import React from 'react'
import {IntlTelCountry, Country} from 'models'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {CountryService} from 'services'
import {setLoading, quitLoading} from 'utils'
interface Props{
    onChange: (isCorrect: boolean, country_phone: string, country: IntlTelCountry) => void,
    preferredCountries?: string[],
    className?: string,
    label?: string,
    labelClass?: string,
    placeholder?: string,
    onPhoneNumberBlur?:() => void
}
const TelInput = (props: Props) => {
    const [country, setCountry] = React.useState<Country | undefined>(undefined)
    const defaultIntlClass = 'intl-tel-input border-gray form-control'
    const load = async () => {
        try {
            setLoading()
            const _country:any = await CountryService.get()
            console.log(_country);
            setCountry(_country)
        } catch (error) {
            console.log('>>: error getting locale')
        }finally{
            quitLoading()
        }
    }
    React.useEffect(() => {
        load()
    }, [])
    return (
        <div className="form-group">
            {
                props.label && (
                    <label className={"intl-label w-100 "+props.labelClass}>
                        {props.label}
                    </label>
                )
            }
            <IntlTelInput
                autoPlaceholder={!!!props.placeholder}
                placeholder={props.placeholder}
                preferredCountries={props.preferredCountries}
                defaultCountry={country?.codigo?.toLowerCase()}
                onPhoneNumberChange={props.onChange}
                inputClassName={props.className || defaultIntlClass}
                onPhoneNumberBlur={props.onPhoneNumberBlur}
            />
        </div>
    )
}

export default TelInput