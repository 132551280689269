import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer} from '@stripe/react-stripe-js';
import CardSection from './CardSection';
import {Button} from "components"
import {setLoading, quitLoading, showError, formatMiles} from "utils";

class CheckoutForm extends React.Component<any> {
  handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    setLoading();
    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    // Use your card Element with other Stripe.js APIs
    const result = await stripe.createToken(cardNumberElement);

    if (result.error) {
      showError(result.error.message);
      quitLoading();
    } else {
      quitLoading();
      cardNumberElement.clear();
      cardExpiryElement.clear();
      cardCvcElement.clear();
      this.props.pay(result.token.id.toString())
      // pass the token to your backend API
    }
  };

  render() {
    const { stripe } = this.props;
    return (
      <div>
        <CardSection
          email={this.props.email}
        />
        <div className="w-100 text-center">
          <Button 
            onClick={() => this.handleSubmit()}
            className="success text-white font-bold button-rounded btn-sm mt-1 mb-3 px-5 text-uppercase"
            disabled={!stripe} 
            label={"pagar "+formatMiles(this.props.total)+' US$'}
          />
        </div>
      </div>
    );
  }
}

export default function InjectedCheckoutForm(props: {
  onSubmit: (token: string) => void,
  email: string,
  total: number
}) {
  React.useEffect(() => {
    quitLoading()
  }, [])
  return (
    <ElementsConsumer>
      {
        ({stripe, elements}) => (
          <CheckoutForm
            email={props.email}
            stripe={stripe}
            elements={elements}
            pay={props.onSubmit}
            total={props.total}
          />
        )
      }
    </ElementsConsumer>
  );
}
