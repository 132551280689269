import {Country, FullCountry} from 'models'
import { axios } from 'utils';
class CountryService {

    static get = ()=>{
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': 'e5cc1f9a-c759-4496-b280-4dc96c0bc1c3',
            }
          }
          
        return new Promise((resolve, reject) => {
            axios
            .post('https://sdvm-prod-api.pervolare.net/geolocalizacion/',null, config)
            .then( (response) => {
                console.log('Geolocation 1',response);
                resolve(response.data.resultado)
                },
              (error) =>
                reject(error)
            );
          });
    }

//   static get = (): Promise<Country> => {
//     return new Promise((resolve, reject) => {
//         fetch('https://extreme-ip-lookup.com/json/')
//             .then( res => res.json())
//             .then((response: Country) => {
//                 resolve(response)
//             })
//             .catch((data) => {
//                 reject(data)
//             });
//     });
//   }

  static countries = (): Promise<FullCountry[]> => {
    return new Promise((resolve, reject) => {
        fetch('https://restcountries.eu/rest/v2/all')
            .then( res => res.json())
            .then((response: FullCountry[]) => {
                resolve(response)
            })
            .catch((data) => {
                reject(data)
            });
    });
  }

}

export {
    CountryService
};
