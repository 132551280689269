import React from 'react'
import {Image, Slider} from 'components'

const MayInterestYouCards = () => {
    const interests = [
        {
            img: 'blog/1.png',
            url: `${process.env.GATSBY_BLOG}/que-sucede-si-mi-vuelo-se-retrasa-o-lo-cancelan/`
        },
        {
            img: 'blog/2.png',
            url: `${process.env.GATSBY_BLOG}/importancia-de-viajar-con-seguro-medico-hoy-en-dia/`
        },
        {
            img: 'blog/3.png',
            url: `${process.env.GATSBY_BLOG}/francia-el-pais-mas-visitado-del-mundo/`
        },
    ]
    return(
        <div className="may-interest-you">
            <div className="w-100 text-center">
                <h4 className="text-purple m-0 p-4">
                    Te puede interesar...
                </h4>
                <div className="container">
                    <Slider
                        slides={interests.length}
                        slidesSm={1}
                    >
                        {
                            interests.map((element, i) => {
                                return(
                                    <div
                                        className=""
                                        key={i}
                                    >
                                        <a
                                            href={element.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="p-4">
                                                <Image
                                                    uri={element.img}
                                                />
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default MayInterestYouCards