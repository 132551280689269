import React from 'react'
import {Button, Input} from 'components'

type Form = {
    offer: string
}
const OffersBanners = () => {
    const IFormState = {
        offer: ''
    }
    const [form, setForm] = React.useState<Form>(IFormState)
    const change = (key: keyof Form, value: string) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    };
    return(
        <div className="home-card-address">
            <div className="bg-light-gray">
              <div className="container text-left">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <h5 className="text-pink">
                      ¿Quieres recibir
                      <span className="text-purple">
                        &nbsp;Ofertas exclusivas&nbsp;
                      </span>
                      de asistencia de viaje?
                    </h5>
                    <p className="text-purple">
                      ¡Regístrate a nuestro boletín de noticias!
                    </p>
                    <div className="offers">
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <div className="container">
                            <Input
                              name="offer"
                              className="default-input"
                              value={form.offer}
                              onChange={(value: string) => change('offer', value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="container">
                            <Button
                              label="Recibir Ofertas"
                              className="pink"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <p className="text-purple">
                      Nosotros
                    </p>
                    <p className="text-purple">
                      Blog
                    </p>
                    <p className="text-purple">
                      Dirección: 4784 EAST MICHIGAN STREET, FL, EE.UU
                    </p>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <p className="text-purple">
                      Ayuda
                    </p>
                    <p className="text-purple">
                      Términos y Condiciones
                    </p>
                    <p className="text-purple">
                      Mapa del sitio
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
    )
}
export default OffersBanners