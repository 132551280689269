import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import {PlanParam, PlanResponse, CoverageResponse, ComparedPlanResponse, CheckoutCheckResponse} from 'models'
class PlanService {

  static get = (form: PlanParam): Promise<PlanResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post('cotizar', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }
  static coverages = (form: {
    id_plan: number,
    id_sql: string
  }): Promise<CoverageResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post('detallesCoberturas', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }
  static compare = (form: {
    planes: number[],
    id_sql: string
  }): Promise<ComparedPlanResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post('compararPlanes', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static check = (form: {
    id_plan: number,
    id_sql: string
  }): Promise<CheckoutCheckResponse> => {
    return new Promise((resolve, reject) => {
      axios
      .post('checkout', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static checkout = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios
      .post('finishCheckout', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) => {
          console.log('>>: error in service > ', error)
          reject(error)

        }
      );
    });
  }

  static checkoutPaypal = (form: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      axios
      .post('finishCheckoutPaypal', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) => {
          console.log('>>: error in service > ', error)
          reject(error)

        }
      );
    });
  }

}

export {
    PlanService
};
