import React from 'react'
import {Image, Slider} from 'components'

type Props = {
  slidesSm?: number,
  slidesMd?: number
  withoutTitle?: boolean
}
const InsurancesCarousel = (props: Props) => {
    const sliders = [
        'insurances/logo-assisnetcard.png',
        'insurances/logo-Assist365.png',
        'insurances/logo-AssistCard.png',
        'insurances/Logo-Axismedical.png',
        'insurances/logo-CardinalAssistance.png',
        'insurances/logo-eua.png',
        'insurances/logo-horizontal--Omint.png',
        'insurances/logo-interwelt.png',
        'insurances/logo-Omint.png',
        'insurances/logo-TAS.png',
    ]
    return(
        <div className="container insurance-carousel-container">
          <div className="w-100 text-center">
            {
              !props.withoutTitle && (
                <p className="text-purple m-0">
                  Compara entre <strong>+ de {sliders.length} compañías</strong> de seguros de viaje
                </p>
              )
            }
            <Slider
              slides={props.slidesMd || 3}
              slidesSm={props.slidesSm || 1}
            >
                {
                  sliders.map((element: string, i: number) => {
                    return(
                      <div className="slide-container p-2" key={i}>
                        <Image
                          uri={element}
                        />
                      </div>
                    )
                  })
                }
            </Slider>
          </div>
        </div>
    )
}
export default InsurancesCarousel;