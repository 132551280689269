import axios, {AxiosError, AxiosResponse ,AxiosInstance} from 'axios';
// import {store} from 'store'
const baseURL = process.env.GATSBY_API_URL;
console.log('baseURL', baseURL);
let _axios: AxiosInstance = axios.create({
  baseURL,
});
_axios.interceptors.request.use(
  (config) => {
    // const { user } = store.getState();
    // if (user && user.token) {
    //   config.headers["Authorization"] = `Bearer ${user.token}`;
    // }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  (response: AxiosResponse) => {
    if(response.data.status === 'error'){
      const keys: string[] = Object.keys(response.data[0])
      if(keys.length){
        const message: string = response.data[0]?.[keys[0]]?.[0]
        if(message)
          throw {
            validations: response.data[0],
            message,
            fromInterceptor: true
          }
      }
    }
    return response;
  }
);
export default _axios;