import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { quitLoading } from 'utils';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY || '');

const Stripe = (props: {
  onSubmit: (token: string) => void,
  email: string,
  total: number
}) => {
  React.useEffect(() => {
    quitLoading()
  }, [])
  console.log('>>: stripePromise > api key > ', stripePromise)
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        onSubmit={props.onSubmit}
        email={props.email}
        total={props.total}
      />
    </Elements>
  );
};

export default Stripe