import React from 'react'
import {Image, Slider} from 'components'
import {Popover, OverlayTrigger} from 'react-bootstrap'
const WellnesBanner = () => {
    const banners =  [
        {
          uri: 'oldman.png',
          title: 'Tercera edad',
          popover: 'Tenemos planes para cualquier edad y con coberturas adecuadas para cada etapa de la vida.'
        },
        {
          uri: 'bag.png',
          title: 'Mochilero',
          popover: 'Las aventuras continúan. Elige el plan adecuado para cada situación de viaje.'
        },
        {
          uri: 'student.png',
          title: 'Estudiante',
          popover: 'Muchas aventuras te esperan estudiando en el exterior. Por requisito y protección: revisa tus opciones para tu seguro estudiantil.'
        },
        {
          uri: 'travel.png',
          title: 'Viajero frecuente',
          popover: 'Si viajas varias veces al año, necesitas un plan acorde a ti. Encuentra aquí el tuyo.'
        },
        {
          uri: 'migrants.png',
          title: 'Expatriados',
          popover: 'Si eres expatriado, recuerda protegerte frente a cualquier imprevisto médico. Elige entre diferentes opciones del mercado.'
        },
    ]
    return(
        <div className="home-banners">
            <div className="container">
                <div className="w-100 text-center p-2">
                    <h2 className="text-purple">
                        Tu bienestar es nuestro objetivo
                    </h2>
                    <p className="text-gray-two font-weight-bold subtitle">En Compara Assist contamos con planes para todo tipo de usuarios.</p>
                </div>
                <Slider
                    slides={banners.length}
                    slidesSm={3}
                >
                    {
                        banners.map((element: {
                            uri: string,
                            title: string,
                            popover: string
                        }, i: number) => {
                            return (
                                <OverlayTrigger
                                    rootClose
                                    trigger="click"
                                    placement="top"
                                    overlay={
                                        <Popover id={"popover-basic-"+i}>
                                            <Popover.Content
                                                className="text-dark-gray"
                                            >
                                                {element.popover}
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <div
                                        className=""
                                        key={i}
                                    >
                                        <div className="card">
                                            <div className="image-container">
                                                <Image
                                                    uri={element.uri}
                                                />
                                            </div>
                                            <h4 className="text-gray-two font-weight-bold">{element.title}</h4>
                                        </div>
                                    </div>
                                </OverlayTrigger>
                            )
                        })
                    }
                </Slider>
            </div>
        </div>
    )
}

export default WellnesBanner;