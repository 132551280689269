import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
class ContactService {

  static send = (form: {
    nombre: string,
    email: string,
    whatsapp: string,
    mensaje: string,
    origen: string,
    ciudad: string
  }): Promise<{status: string}> => {
    return new Promise((resolve, reject) => {
      axios
      .post('registrarContacto', form)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
    ContactService
};
