import React from 'react'
import { Button } from 'components'
import { navigate } from 'gatsby'

type Props = {
    navigateTo: string
}
export default (props: Props) => {
    return(
        <div className="back-button">
            <Button
                onClick={() => navigate(props.navigateTo)}
                className="link text-purple"
                icon="arrow-left"
            />
        </div>
    )
}