import React from 'react'

const WhyUsCard = () => {
    return(
        <div className="why-us">
            <div className="row">
                <div className="col-md-6 col-sm-12 why-us-container p-2">
                    <div className="why-us-container-card p-4">
                        <h2 className="text-purple w-100 text-center font-weight-bold">
                            ¿Por qué es importante adquirir un seguro de viaje?
                        </h2>
                        <p className="text-dark-gray text-left">
                            Situaciones inesperadas como: accidentes, pérdida de pasaporte, enfermedades, robos, cancelación de viajes, incluso covid son circunstancias nada agradables que no se suelen prever, pero que nadie está exento de que le sucedan.
                        </p>
                        <p className="text-dark-gray text-left">
                            Por eso, la importancia de adquirir un seguro de viaje: poder sobrellevar mejor este tipo de circunstancias si se presentan
                        </p>
                        <p className="text-purple">
                            ¡No juegues al azar con tu bienestar!.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 why-us-container p-2">
                    <div className="why-us-container-card p-4">
                        <h2 className="text-purple w-100 text-center font-weight-bold">
                            Con Compara Assist tu decides
                        </h2>
                        <p className="text-dark-gray text-left">
                            ¿Por qué buscar de página en página un seguro de viaje si puedes hacer la búsqueda en un solo lugar?
                        </p>
                        <p className="text-dark-gray text-left">
                            Ahorra tiempo: Cada viajero es diferente y tiene distintas necesidades. Ya sea viajes de turismo, por placer o estudios, tienes el poder de comparar y elegir qué compañía y beneficios son los ideales para tus necesidades.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUsCard;