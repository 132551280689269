import {axios} from 'utils';
import {AxiosResponse, AxiosError} from 'axios'
import { Candidate, QuoteRequest } from 'models';
class CandidateService {

  static save = (form: QuoteRequest, sqlId: string = ''): Promise<{id_sql: string, msg: string}> => {
    return new Promise((resolve, reject) => {
      axios
      .post('change-candidate', {
        ...form,
        sqlId
      })
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

  static get = (id: string): Promise<Candidate> => {
    return new Promise((resolve, reject) => {
      axios
      .get('get-candidate/'+id)
      .then(
        (response: AxiosResponse) =>
          resolve(response.data),
        (error: AxiosError) =>
          reject(error)
      );
    });
  }

}

export {
    CandidateService
};
