import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import {Image, Modal, Icon} from 'components'
import {WHATSAPP_LINK, CALENDLY_LINK, BLOG_LINK, useWindowSize} from 'utils'
import {Logo, Contact} from 'images'

type Props = {
  isSticky: boolean
}

const Header = (props: Props) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';
  console.log('url', url);
  let insideMovil = false;
  if(url.includes("buscar") || url.includes("checkout")){
    insideMovil = true;
  }
  const [visible, setVisible] = React.useState<boolean>(false)
  const [width, height] = useWindowSize()
  const icons = [
    {
      name: 'Quienes somos',
      icon: 'us.png',
      redirect: '/sobre-nosotros'
    },
    {
      name: 'Servicios',
      icon: 'services.png',
      redirect: '/servicios'
    },
    {
      name: 'Preguntas frecuentes',
      icon: 'faq.png',
      redirect: '/preguntas-frecuentes'
    },
    {
      name: 'Contacto',
      icon: 'contact.png',
      redirect: '/contacto'
    },
    // {
    //   name: 'Blog',
    //   icon: 'blog.png',
    //   redirect: '/blog'
    // },
  ]
  return (
    <React.Fragment>
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        styles={{
          overlay:{
            zIndex: 2
          },
          content:{
            overflowX: 'hidden',
            maxHeight: width <= 800 ? '100%' : '26%'
          }
        }}
      >
        <div className="w-100 navigation-modal">
          <div className="container">
            <div className="row">
              <React.Fragment>
                {
                  icons.map((element, i) => {
                    return(
                      <div
                        className="col-md col-sm-12 item"
                        key={i}
                      >
                        <Link
                          to={element.redirect}
                        >
                          <div className="container image-container">
                            <Image
                              uri={element.icon}
                            />
                          </div>
                          <h5 className="text-purple"> {element.name} </h5>
                        </Link>
                      </div>
                    )
                  })
                }
              </React.Fragment>
              <div
                className="col item"
              >
                <a
                  href={BLOG_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="container image-container">
                    <Image
                      uri="blog.png"
                    />
                  </div>
                  <h5 className="text-purple"> Blog </h5>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <header className={`header ${props.isSticky ? ' sticky' : ''}`} >
        <div className="p-1">
          <div className="row">
            <div className="col-8 text-left">
              <div className="logo-container">
                <Link
                  to="/"
                >
                  <img
                    src={Logo}
                    alt="Compara Assist - logo"
                  />
                  {/* <Image
                    uri="logo.png"
                  /> */}
                </Link>
              </div>
            </div>
            <div className="col-4 right-container text-right">
              <div className="row w-100 justify-content-end">
                <div className="col-3 col-md-2 p-0 col-whatsapp-container">
                  <div className="w-100 text-right">
                    <div className="whatsapp-container">
                      <a
                        href={WHATSAPP_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon
                          name="whatsapp text-gray"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-3 col-md-2 p-0 callcenter">
                  <div className="text-right callcenter-container">
                    <a
                      href={CALENDLY_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Agendar llamada"
                    >
                      <img
                        src={Contact}
                        alt="Contacta a compara assist"
                        className="m-0"
                      />
                    </a>
                  </div>
                </div>
                {
                  insideMovil ? '' : <div className="col-3 col-md-2 p-0 menu-container text-left">
                  <Icon
                    name="bars cursor-pointer text-pink navbar-toggler p-0"
                    onClick={() => setVisible(true)}
                  />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
