import React from 'react'
import {Complete, Compare, Consulting, List, CreditCard} from 'images'
import {Slider} from 'components'
const FeaturesCard = () => {
    const features = [
        {
            icon: Complete,
            title: 'Completa los datos',
            text: ' solicitados.'
        },
        {
            icon: Compare,
            title: 'Revisa y compara',
            text: ' las opciones de seguros disponibles.'
        },
        {
            icon: Consulting,
            title: 'Obtén la información',
            text: ' inmediata a través de nuestros asesores.'
        },
        {
            icon: List,
            title: 'Escoge la opción',
            text: ' que más te gusta.'
        },
        {
            icon: CreditCard,
            title: 'Realiza tu pago',
            text: ' y empieza a disfrutar de tu cobertura.'
        },
    ]
    return(
        <div className="features">
            <div className="w-100 text-center">
                <h3 className="text-purple mt-2">
                    ¡Elegir un seguro de viaje nunca había sido tan fácil!
                </h3>
                <div className="p-2">
                    <Slider
                        slides={features.length}
                        slidesSm={2}
                    >
                        {
                            features.map((element, i) => {
                                return(
                                    <div
                                        className=" w-100 text-center p-2"
                                        key={i}
                                    >
                                        <div
                                            className="feature-card data p-2"
                                        >
                                            <img
                                                src={element.icon}
                                                alt={element.title+element.text}
                                                style={{
                                                    display: 'initial'
                                                }}
                                            />
                                            <p className="text-pink">
                                                {element.title}
                                                <span className="text-dark-gray">
                                                    {element.text}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    <p className="text-pink m-0">
                        Este es un momento importante:
                    </p>
                    <p className="text-dark-gray">
                        Ya escogiste tu destino, ahora es el momento de elegir quien te respalda y protege en tu próximo viaje
                    </p>
                </div>
            </div>
        </div>
    )
}

export default FeaturesCard;