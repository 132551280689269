import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Header from './Header'
import Footer from './Footer'
import 'scss/fonts/stylesheet.css'
import 'scss/main.scss'
import moment from 'moment'
import 'moment/locale/es'  // without this line it didn't work

interface Props {
    children: React.ReactNode
}
const DefaultLayout = (props: Props) =>{
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                title
                }
            }
        }
    `)
    const [isSticky, setSticky] = React.useState<boolean>(false);
    const ref = React.useRef(null);
    const handleScroll = () => {
        const scrollPosition = document.body.getBoundingClientRect().top * -1
        if(scrollPosition > 0 && scrollPosition >= window.screen.availHeight && !isSticky){
            setSticky(true)
        }else{
            setSticky(false)
        }
    };
    React.useEffect(() => {
        moment.locale("es")
        const head = document.getElementsByTagName('body')[0];
	   	const script = document.createElement('script');
	   	script.src = '//code.jivosite.com/widget/PKaIo1gc3r';
	   	script.type = 'text/javascript';
		script.async = true;
		script.defer = true;
	   	head.appendChild(script);

        //Sticky header
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [])
    return(
        <>
            <div
                style={{
                    margin: `0 auto`,
                    height: '100vh'
                }}
            >
                <div
                    className="header-container container"
                    ref={ref}
                >
                    <Header
                        siteTitle={data.site.siteMetadata?.title || `Title`}
                        isSticky={isSticky}
                    />
                </div>
                <main
                    style={{
                        minHeight: '91vh'
                    }}
                >
                    {props.children}
                </main>
                <Footer
                
                />
            </div>
        </>
    )
}

export default DefaultLayout