import React from 'react'
import {Icon} from 'components'
import {LogoWhite} from 'images'
type Icon = {
    type: string,
    url: string
}
const Footer = () => {
    const defaultIcons: Icon[] = [
        {
            type: 'facebook',
            url: 'https://www.facebook.com/comparaassist'
        },
        {
            type: 'twitter',
            url: 'https://twitter.com/AssistCompara'
        },
        {
            type: 'linkedin',
            url: 'https://www.linkedin.com/in/compara-assist-a45b701a0/'
        },
        {
            type: 'youtube',
            url: 'https://www.youtube.com/channel/UCeGRWE0Jb696UIHT0_EOpCA'
        },
        {
            type: 'instagram',
            url: 'https://www.instagram.com/comparaassist'
        },
    ]
    const [icons, setIcons] = React.useState<Icon[]>(defaultIcons)
    return(
        <div className="footer container">
            <div className="row">
                <div className="col-md-4 col-sm-12 logo-container p-4">
                    <div className="container text-center">
                        <div className="img-responsive">
                            <img
                                className="m-0"
                                src={LogoWhite}
                                alt="Compara Assist - logo"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    <div className="row items-container">
                        <div className="col-md-6 col-sm-12">
                            <p className="text-white text-center">Términos y Condiciones</p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <p className="text-white text-center">
                                FAQ
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 icons-container">
                    {
                        icons.map((element: Icon, i: number) => {
                            return(
                                <a
                                    key={i.toString()}
                                    href={element.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon
                                        key={i}
                                        name={element.type+' text-white footer-icon p-2'}
                                    />
                                </a>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Footer;