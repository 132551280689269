import Whatsapp from './whatsapp.svg'
import BannerWeb from './banner.jpeg'
import ImgEscritorio from './img_escritorio.jpg'
import Conversation from './conversation.svg'
import Check from './check.svg'
import Platforms from './platforms.svg'
import Search from './search.svg'
import Airplane from './airplane.svg'
import LogoWhite from './logo-white.svg'
import ContactBanner from './contact-banner.jpg';
import AirplaneSeparator from './airplane-separator.svg'
import FaqBanner from './faq-banner.jpg';
import BannerHome from './banner-compara.jpeg'
import BannerHomeMovil from './fondo-movil.jpeg'
import BannerHomeMovil2 from './fondo-movil2.jpeg'
import Compare from './home-features/compare.svg'
import Complete from './home-features/complete.svg'
import Consulting from './home-features/consulting.svg'
import CreditCard from './home-features/credit-card.svg'
import List from './home-features/list.svg'
import Blog_1 from './blog/1.png'
import Blog_2 from './blog/2.png'
import Blog_3 from './blog/3.png'
import TravelInsuranceBanner from './travel-insurance-banner.jpg'
import CentralAmericanMap from './second-step/central-america-map.svg'
import EuropeMap from './second-step/europe-map.svg'
import NationalMap from './second-step/national-map.svg'
import NorthAmericaMap from './second-step/north-america.svg'
import OffMap from './second-step/off-map.svg'
import SouthAmericanMap from './second-step/south-america-map.svg'
import WorldMap from './second-step/world-map.svg'
import Grid2X2 from './grid2x2.svg'
import Grid1XX from './grid1xX.svg'
import CheckoutBg from './checkout-bg.jpg'
import Pets from './pets.svg'
import DamageToThirdParties from './damage-to-third-parties.svg'
import PreExistence from './pre-existence.svg'
import TechProtection from './tech-protection.svg'
import PetsSelected from './pets-selected.svg'
import DamageToThirdPartiesSelectedd from './damage-to-third-parties-selected.svg'
import PreExistenceSelected from './pre-existence-selected.svg'
import TechProtectionSelected from './tech-protection-selected.svg'
import TravelUser from './travel-user.png'
import Isotipo from './isotipo.svg'
import Logo from './logo.png'
import Contact from './contact.png'
import BannerOnlineDoctor from './banner-online-doctor.jpg';
import ContactOkMail from './contact-ok-mail.svg'
import BackgroundHeart from './background-hearth.png'
import OnlineAssistant from './online-assistant.jpg'
import VectorAirplane from './airplane.png'
import Ambulance from './ambulance.png'
import AssistCard from './assist-card.png'
import Dentalist from './dentalist.png'
import Hospital from './hospital.png'
import Hotel from './hotel.png'
import Pills from './pills.png'
import RedWorld from './red-world.png'
import WebBannerAssistCard from './web-banner-assistcard.jpg'
import World from './world.png'

export {
    VectorAirplane,
    Ambulance,
    AssistCard,
    Dentalist,
    Hospital,
    Hotel,
    Pills,
    RedWorld,
    WebBannerAssistCard,
    World,
    OnlineAssistant,
    BackgroundHeart,
    ContactOkMail,
    BannerOnlineDoctor,
    Contact,
    Logo,
    Isotipo,
    TravelUser,
    PetsSelected,
    DamageToThirdPartiesSelectedd,
    PreExistenceSelected,
    TechProtectionSelected,
    Pets,
    DamageToThirdParties,
    PreExistence,
    TechProtection,
    CheckoutBg,
    Grid1XX,
    Grid2X2,
    CentralAmericanMap,
    EuropeMap,
    NationalMap,
    NorthAmericaMap,
    OffMap,
    SouthAmericanMap,
    WorldMap,
    TravelInsuranceBanner,
    Blog_1,
    Blog_2,
    Blog_3,
    Compare,
    Complete,
    Consulting,
    CreditCard,
    List,
    BannerHome,
    BannerHomeMovil,
    BannerHomeMovil2,
    FaqBanner,
    AirplaneSeparator,
    ContactBanner,
    LogoWhite,
    Airplane,
    Conversation,
    Check,
    Platforms,
    Search,
    ImgEscritorio,
    BannerWeb,
    Whatsapp
}