import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {useWindowSize} from 'utils'
type Props = {
    slides: number,
    slidesSm?: number,
    children: JSX.Element | JSX.Element[]
}
const _Slider = (props: Props) => {
    const [width, height] = useWindowSize();
    const getTotalSlides = (): number => {
        let value: number = props.slides
        if(width <= 980){
            value =  props.slidesSm || props.slides
        }
        return value
    }
    return(
        <Slider
            dots={false}
            infinite={true}
            autoplay={true}
            slidesToShow={getTotalSlides()}
            className="overflow-hidden"
        >
            {
                props.children
            }
        </Slider>
    )
}

export default _Slider