/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import {Isotipo} from 'images'
import {Icon} from 'components'
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const CardSection = (props: {
  email: string
}) => {
  return (
      <div className="" id="stripe-card">
        <div className="logo-container w-100 text-center card-header">
          <h4 className="text-purple m-0 p-1">
            Compara Assist
          </h4>
          <img
            src={Isotipo}
            className="m-0 p-1"
            alt="Compara assist"
          />
          <p className="text-gray m-0">
            {props.email}
          </p>
        </div>
        <div className="container p-2">
          <div className="row">
            <div className="col-2 text-left w-100">
              <Icon
                name="credit-card text-purple "
              />
            </div>
            <div className="col-10">
              <CardNumberElement
                options={CARD_ELEMENT_OPTIONS}
                className="element p-1"
              />
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-2 text-left w-100">
                  <Icon
                    name="calendar text-purple"
                  />
                </div>
                <div className="col-10">
                  <CardExpiryElement
                    options={CARD_ELEMENT_OPTIONS}
                    className="element p-1"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-2">
                  <Icon
                    name="lock text-purple"
                  />
                </div>
                <div className="col-10">
                  <CardCvcElement
                    options={CARD_ELEMENT_OPTIONS}
                    className="element p-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CardSection;